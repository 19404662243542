import React from 'react';
import './index.css';

import { Routes, Route } from 'react-router-dom';  // Import Routes and Route from react-router-dom
import HomePage from './pages/HomePage';
import Navbar from './components/Navbar';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import Footer from './components/Footer';
import Resume from './pages/Resume';
import Contact from './pages/Contact';

const App = () => {
  return (
    <div className='bg-backgroundColor'>
      <Navbar />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<About />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/resume' element={<Resume />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
