import React from 'react';
import { InstagramEmbed } from 'react-social-media-embed';

const VideoSection = ({ title, videos }) => {
  return (
    <section className="w-full max-w-6xl mx-auto p-6">
      {/* Title with Icon */}
      <div className="flex items-center justify-center gap-6 mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 underline decoration-primaryRed underline-offset-4 hover:text-primaryRed transition-all duration-300">
          {title}
        </h2>
      </div>

      {/* Grid for YouTube videos */}
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-6 transition-opacity duration-300">
        {videos
          .filter(video => video.platform === 'youtube')
          .map(video => (
            <div key={video.id} className="relative overflow-hidden rounded-lg shadow-lg">
              <iframe
                width="100%"
                src={`https://www.youtube.com/embed/${video.youtubeId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          ))}
      </div>

      {/* Grid for Instagram videos */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-8">
        {videos
          .filter(video => video.platform === 'instagram')
          .map(video => (
            <div key={video.id} className='flex justify-center'>
                <InstagramEmbed url={video.instagramId} width={328}/>
            </div>
          ))}
      </div>
    </section>
  );
};

export default VideoSection;
