import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

const PhotoSection = ({ title, photos, sectionId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <section className="w-full max-w-6xl mx-auto p-4">
      {/* Title with Icon */}
      <div className="flex items-center justify-center gap-6 mb-6" onClick={() => setIsOpen(!isOpen)}>
        <h2 className="text-2xl cursor-pointer font-semibold text-gray-800 underline decoration-primaryRed underline-offset-4 hover:text-primaryRed transition-all duration-300">
          {title}
        </h2>
        <FaChevronDown
          className={`text-lg text-primaryRed cursor-pointer transform transition-transform duration-300 ${
            isOpen ? 'rotate-180' : 'rotate-0'
          }`}
        />
      </div>

      {/* Content (conditionally rendered based on toggle) */}
      {isOpen && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 transition-opacity duration-300">
          {photos.map((photo) => (
            <div
              key={photo.id}
              className="relative overflow-hidden rounded-lg shadow-lg group cursor-pointer"
              onClick={() => handleImageClick(photo.src)}
            >
              {/* Image */}
              <img
                src={photo.src}
                alt={photo.alt}
                className="w-full h-64 object-cover transition-transform duration-500 transform group-hover:scale-105"
              />

              {/* Overlay */}
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-500 flex flex-col justify-center items-center text-white text-center p-4">
                <h3 className="text-xl font-bold mb-2">{photo.title}</h3>
                <p className="text-sm">{photo.alt}</p>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Modal for Enlarged Image */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative rounded-md">
            {/* Close Button */}
            <button
              className="absolute top-2 right-2 text-primaryRed font-bold bg-white bg-opacity-40 px-2 rounded-lg text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>

            {/* Enlarged Image */}
            <img
              src={selectedImage}
              alt="Enlarged"
              className="max-w-full max-h-screen rounded-lg shadow-lg"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default PhotoSection;
