const projectData = [
    {
      id: 1,
      title: 'Book Inventory App',
      description: 'This project is a Book Inventory Management System built with a React frontend, Tailwind CSS for styling, and a Node.js/Express backend with PostgreSQL as the database. This project was developed as part of a learning project and an assignment for an internship opportunity at Second Bind.',
      image: 'https://images.pexels.com/photos/2908984/pexels-photo-2908984.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      link: 'https://github.com/sarniraula/book_inventory'
    },
    {
      id: 2,
      title: 'MERN Blog App',
      description: 'A fully responsive MERN stack blog application with features like user authentication (JWT & Google OAuth), admin dashboard for managing posts, comments, and users, advanced search functionality, and dark mode. Using React, Tailwind CSS, Redux Toolkit, and MongoDB, this project ensures a dynamic, secure, and interactive user experience.',
      image: 'https://img.freepik.com/free-photo/technology-communication-icons-symbols-concept_53876-120314.jpg?t=st=1725212567~exp=1725216167~hmac=a6c51344f826d481bca6bb053b53dc7dcf7c8d8604340f899f3876d7fab55771&w=740', // Replace with actual image paths
      link: 'https://github.com/sarniraula/mern-blog'
    },
    {
      id: 3,
      title: 'Wedding Site',
      description: 'This project is a custom wedding website created for Jan and Alfie, friends from the Philippines, to enhance the experience and accessibility of their wedding celebration. Developed using React and Tailwind CSS, the website is designed to be visually appealing and user-friendly. Key features includes Google Maps Integration, RSVP Form, Event Countdown, and Image Galleries. This project combines functionality and elegance, creating a memorable online experience for Jan and Alfie’s special day.',
      image: 'https://images.pexels.com/photos/3342697/pexels-photo-3342697.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      link: 'https://wedding-mocha-theta.vercel.app/'
    },
    {
      id: 4,
      title: 'Memory Boost',
      description: 'A flashcard mobile application for learning with customizable decks and reviews. Built with React Native, Expo, and Firebase, this project aims to help users memorize information more effectively and efficiently.',
      image: 'https://img.freepik.com/free-photo/still-life-with-human-brains-watering-can_23-2150547703.jpg?t=st=1728486231~exp=1728489831~hmac=367209a7a23c0cb0b40ec2a0f84af9dda68d68c4822afb6d5b20c85cd4b5b29d&w=740',
      link: 'https://play.google.com/store/apps/details?id=com.amir.memoryboost'
    },
    {
      id: 5,
      title: 'Project Four',
      description: 'A full stack application built with React and Node.js.',
      image: 'https://img.freepik.com/free-photo/3d-monsoon-season-sale_23-2150472541.jpg?t=st=1725212831~exp=1725216431~hmac=9a7301beac3bf9ba6da9addc4994adc49daba3385df9848d8c7fdae24499fd64&w=826', // Replace with actual image paths
      link: 'https://github.com/yourusername/project-one'
    },
    
  ];
  
  export default projectData;
  