import React, { useState } from 'react';
import Sidebar from '../components/PortfolioSidebar';
import ProfessionalProjects from '../components/Projects';
import Photography from '../components/Photography';
import Videography from '../components/Videography';
import { Helmet } from 'react-helmet';

const Portfolio = () => {
  const [activeSection, setActiveSection] = useState('ProfessionalProjects');

  const renderSection = () => {
    switch (activeSection) {
      case 'ProfessionalProjects':
        return <ProfessionalProjects />;
      case 'Photography':
        return <Photography />;
      case 'Videography':
        return <Videography />;
      default:
        return <ProfessionalProjects />;
    }
  };

  return (
    <div className='min-h-screen flex flex-col md:flex-row'>
      <Helmet>
        <title>Portfolio Page - Saroj Niraula | Developer Portfolio - Saroj Niraula | ON, Canada</title>
        <meta name="description" content="Explore the portfolio of Saroj Niraula, a Full Stack Developer based in ON, Canada and view his professional projects, photography, and videography." />
      </Helmet>
      <Sidebar activeSection={activeSection} setActiveSection={setActiveSection} />
      <div className='mx-auto md:mx-20 p-6 w-full'>
        {renderSection()}
      </div>
    </div>
  );
};

export default Portfolio;
