import React from 'react';
import icons from '../assets/data/iconsData';
import WordGuessingGame from '../components/WordGuessingGame';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <div className='container flex flex-col px-6 pb-10 mx-auto mt-12 space-y-10'>
      <Helmet>
        <title>About Page - Saroj Niraula | Developer Portfolio - Saroj Niraula | ON, Canada</title>
        <meta name="description" content="Explore the portfolio of Saroj Niraula, a Full Stack Developer based in ON, Canada." />
      </Helmet>
      <div className='text-center'>
        <h1 className='text-4xl font-bold text-primaryRed mb-5'>About Me</h1>
        <p className='max-w-2xl mx-auto text-lg text-darkGrayishBlue mb-5'>
          Professionally, I am a Full Stack Developer with a passion for building dynamic, user-friendly web applications. But there's more to me than just code. I'm also an avid photographer and videographer, capturing stunning landscapes whenever I travel. In my free time, I enjoy a variety of hobbies like basketball, swimming, skateboarding, and cycling. My life is a blend of technology, creativity, and adventure.
        </p>
      </div>


      <div className='grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-4'>
        <div className='flex flex-col items-center justify-center bg-gradient-to-r from-slate-100 to-slate-200 p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300'>
          <h2 className='text-2xl font-bold text-primaryRed mb-4'>Professional Skills</h2>
          <div className='grid grid-cols-5 gap-4'>
            {icons.skillIcons.map((icon) => (
              <div key={icon.id} className='flex items-center space-x-4 transition-transform transform hover:scale-105 duration-200'>
                {icon.icon}
              </div>
            ))}
          </div>
        </div>

        {/* Hobbies */}
        <div className='flex flex-col items-center justify-center bg-gradient-to-r from-slate-100 to-slate-200 p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300'>
          <h2 className='text-2xl font-bold text-primaryRed mb-4'>Leisure Activities</h2>
          <div className='grid grid-cols-5 gap-4'>
            {icons.hobbyIcons.map((icon) => (
              <div key={icon.id} className='flex items-center space-x-4 transition-transform transform hover:scale-105 duration-200'>
                {icon.icon}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='flex justify-center mt-12'>
        <WordGuessingGame />
      </div>
    </div>
  );
};

export default About;
