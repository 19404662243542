import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa'; // Icons for GitHub and LinkedIn
import ProfilePhoto from '../assets/img/Salaam.png'; // Add your profile photo
import ResumePDF from '../assets/data/Resume.pdf'; // Path to your resume PDF

const Resume = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      {/* Profile Section */}
      <div className="flex flex-col items-center md:flex-row md:items-start mb-12">
        {/* Profile Picture */}
        <div className="w-48 h-48 mb-6 md:mb-0 md:mr-8">
          <img
            src={ProfilePhoto} 
            alt="Saroj Niraula"
            className="rounded-full shadow-lg object-cover w-full h-full"
          />
        </div>

        {/* Profile Info */}
        <div className="text-center md:text-left">
          <h1 className="text-4xl font-bold text-primaryRed mb-2">Saroj Niraula</h1>
          <h2 className="text-2xl text-gray-700 mb-4">Full Stack Developer</h2>
          <p className="text-gray-600 mb-4">
            Dedicated and detail-oriented professional with a passion for providing efficient and innovative solutions in web and mobile applications.
          </p>

          {/* Social Media Links */}
          <div className="flex justify-center md:justify-start space-x-4 mb-4">
            <a href="https://github.com/sarniraula" target="_blank" rel="noopener noreferrer">
              <FaGithub className="text-2xl text-gray-700 hover:text-primaryRed transition duration-300" />
            </a>
            <a href="https://www.linkedin.com/in/saroj-niraula-288991165/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="text-2xl text-gray-700 hover:text-primaryRed transition duration-300" />
            </a>
          </div>

          {/* Download Resume Button */}
          <a
            href={ResumePDF}
            download="Saroj_Niraula_Resume.pdf"
            className="bg-primaryRed text-white px-6 py-2 rounded-full hover:bg-red-600 transition duration-300"
          >
            Download Resume
          </a>
        </div>
      </div>

      {/* Professional Experience Section with Timeline */}
      <div className="mb-12">
        <h3 className="text-3xl font-bold text-primaryRed mb-6">Professional Experience</h3>
        
        {/* Timeline */}
        <div className="relative border-l-2 border-primaryRed">
          
          {/* The Bio Sparrow Experience */}
          <div className="mb-10 ml-6">
            <div className="absolute w-4 h-4 bg-primaryRed rounded-full mt-1.5 -left-2.5 border border-white"></div>
            <p className="text-sm text-gray-600">04/2023 – Present</p>
            <h4 className="text-xl font-semibold">Mobile Application Developer, The Bio Sparrow</h4>
            <p className="text-gray-600 mb-2">Remote, USA</p>
            <p className="text-gray-600">
              Contributing to mobile and web applications using React Native, Flutter, Node.js, and PostgreSQL. Participated in R&D meetings and implemented feedback to improve hand therapy devices.
            </p>
          </div>
          
          {/* Capkon Group Experience */}
          <div className="mb-10 ml-6">
            <div className="absolute w-4 h-4 bg-primaryRed rounded-full mt-1.5 -left-2.5 border border-white"></div>
            <p className="text-sm text-gray-600">10/2019 – 12/2022</p>
            <h4 className="text-xl font-semibold">Operations Manager, Capkon Group Nepal Pvt. Ltd.</h4>
            <p className="text-gray-600 mb-2">Lalitpur, Nepal</p>
            <p className="text-gray-600">
              Led a team of engineers and designers for a Sydney-based business. Ensured system performance, legal compliance, and coordinated project deliverables on time and within budget.
            </p>
          </div>

          {/* Keela Experience */}
          <div className="mb-10 ml-6">
            <div className="absolute w-4 h-4 bg-primaryRed rounded-full mt-1.5 -left-2.5 border border-white"></div>
            <p className="text-sm text-gray-600">03/2018 – 04/2019</p>
            <h4 className="text-xl font-semibold">Developer / QA Automation Engineer, Keela</h4>
            <p className="text-gray-600 mb-2">Lalitpur, Nepal</p>
            <p className="text-gray-600">
              Worked as a Web Developer and Automation Engineer at Keela.co. Analyzed existing programs, researched and designed new software systems, and wrote automated tests using Cypress.js to ensure software quality.
            </p>
          </div>
        </div>
      </div>

      {/* Education Section */}
      <div className="mb-12">
        <h3 className="text-3xl font-bold text-primaryRed mb-6">Education</h3>
        <div className="mb-6 flex">
            <div>
                <img src="https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Logos/Seneca_Logo.png" alt="Seneca College" className="w-20 inline-block mr-2" />
            </div>
            <div>
                <h4 className="text-xl font-semibold">Business Information Technology, Post-Graduate</h4>
                <p className="text-gray-700 mb-2">Seneca International Academy, Toronto, Canada</p>
                <p className="text-gray-600">A 2-year post-graduate course in Business Information Technology.</p>
            </div>
        </div>
        <div className="mb-6 flex">
          <div>
            <img src="https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Logos/TU.png" alt="Seneca College" className="w-20 inline-block mr-2" />
          </div>
          <div>
            <h4 className="text-xl font-semibold">Bachelor of Computer Engineering</h4>
            <p className="text-gray-700 mb-2">Pulchowk Engineering Campus, Tribhuvan University, Nepal</p>
            <p className="text-gray-600">A 4-year undergraduate course completed with a scholarship.</p>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Resume;
