import React, { useState } from 'react';
import logo from '../assets/img/logob11.png';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons for the hamburger menu

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false); // State to manage menu visibility

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle the menu open/close state
  };

  return (
    <div className='border-b-2 border-slate-200'>
      <nav className='relative mx-auto p-3 md:mx-20'>
        <div className='flex items-center justify-between'>
          {/* Logo */}
          <div className='p-2 bg-gradient-to-r from-slate-200 to-slate-50 rounded-full shadow-primary hover:scale-105 transition duration-300 ease-in-out hover:shadow-lg'>
            <img className='h-16 md:h-20' src={logo} alt='' />
          </div>

          {/* Hamburger Icon */}
          <div className='md:hidden' onClick={toggleMenu}>
            {menuOpen ? <FaTimes className='text-2xl' /> : <FaBars className='text-2xl' />}
          </div>

          {/* Menu items */}
          <div className={`text-xs space-x-10 font-sans font-semibold hidden md:block`}>
            <a href='/' className='hover:text-primaryRed'>HOME</a>
            <a href='/about' className='hover:text-primaryRed'>ABOUT ME</a>
            <a href='/portfolio' className='hover:text-primaryRed'>PORTFOLIO</a>
            <a href='/resume' className='hover:text-primaryRed'>RESUME</a>
          </div>

          {/* Button */}
          <a className='group p-4 px-5 shadow-primary hidden md:block rounded baseline bg-gradient-to-r from-slate-200 to-slate-50
            hover:from-primaryRed hover:to-primaryRed'>
            <p className='text-primaryRed text-sm font-medium font-serif group-hover:text-white'>
              <a href='/contact'>CONTACT ME</a>
            </p>
          </a>
        </div>

        {/* Mobile Menu */}
        {menuOpen && (
          <div className='md:hidden mt-4'>
            <div className='flex flex-col text-sm items-end space-y-2'>
              <a href='/' className='block hover:text-primaryRed'>HOME</a>
              <a href='/about' className='block hover:text-primaryRed'>ABOUT ME</a>
              <a href='/portfolio' className='block hover:text-primaryRed'>PORTFOLIO</a>
              <a href='/resume' className='block hover:text-primaryRed'>RESUME</a>
              <a className='p-2 shadow-primary rounded baseline bg-gradient-to-r from-slate-200 to-slate-50 hover:from-primaryRed hover:to-primaryRed'>
                <p className='text-primaryRed text-sm font-medium font-serif hover:text-white'>
                  <a href='/contact'>CONTACT ME</a>
                </p>
              </a>
            </div>
          </div>
        )} 
      </nav>
    </div>
  );
};

export default Navbar;
