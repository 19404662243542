import React, { useState } from 'react';
import projectData from '../assets/data/projectData'; 

const ProfessionalProjects = () => {
  return (
    <section className='p-6'>
      <h2 className='text-4xl font-bold text-center text-primaryRed mb-10'>My Projects</h2>
      <div className='grid grid-cols-1 gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
        {projectData.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
    </section>
  );
};

const ProjectCard = ({ project }) => {
  const [isReadMore, setIsReadMore] = useState(false);
  return (
    <div className='bg-white shadow-lg rounded-lg overflow-hidden'>
      <img
        src={project.image}
        alt={project.title}
        className='w-full h-48 object-cover'
      />
      <div className='p-4'>
        <h3 className='text-lg font-bold'>{project.title}</h3>
        <p className='mt-2 text-xs text-gray-600'>
          { isReadMore ? project.description : `${project.description.substring(0, 100)}${project.description.length > 100 ? '...' : ''}`}
          { project.description.length > 100 && (
            <button
            onClick={() => setIsReadMore(!isReadMore)}
            className='text-primaryRed' >
              {isReadMore ? 'show less' : 'read more'}
            </button>
          )}
        </p>
        <a
          href={project.link}
          target='_blank'
          rel='noreferrer'
          className='block text-white m-auto mt-5 rounded-md bg-primaryRed text-center w-1/2 hover:underline'
          >Lets go</a>
      </div>
    </div>
  );
};

export default ProfessionalProjects;
