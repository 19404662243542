import React, { useState } from 'react';
import questions from '../questions.json';

const WordGuessingGame = () => {
  // Game state variables
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userGuess, setUserGuess] = useState('');
  const [message, setMessage] = useState('');
  const [showImage, setShowImage] = useState(false);
  const [feedbackImage, setFeedbackImage] = useState('');

  // Function to handle user guess
  const handleGuess = () => {
    const currentQuestion = questions[currentQuestionIndex];

    // Check if the user's guess is one of the correct answers
    if (currentQuestion.answers.includes(userGuess.trim())) {
      setMessage('Correct! 🎉');
      setFeedbackImage(currentQuestion.correctImage);
      setShowImage(true);
    } else {
      setMessage(currentQuestion.incorrectFeedback);
      setFeedbackImage(''); // Optionally reset image for incorrect answers
      setShowImage(false);
    }

    setUserGuess(''); // Reset the input
  };

  // Function to handle user input change
  const handleInputChange = (event) => {
    setUserGuess(event.target.value);
  };

  // Function to go to the next question
  const handleNextQuestion = () => {
    setShowImage(false); // Hide image for the next question
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setMessage('Game Over! You completed all questions. 🎉');
    }
  };

  return (
    <div className='bg-gray-100 p-6 rounded-lg shadow-lg text-center'>
      <h2 className='text-2xl font-bold mb-4 text-primaryRed'>How well do you know me!</h2>
      <p className='text-lg mb-3'>{questions[currentQuestionIndex].question}</p>
      <input
        type='text'
        value={userGuess}
        onChange={handleInputChange}
        className='border border-gray-300 p-2 rounded mb-4'
        placeholder='Your Guess...'
      />
      <button
        onClick={handleGuess}
        className='bg-primaryRed text-white px-4 py-2 rounded hover:bg-red-700 transition duration-200'
      >
        Submit
      </button>
      <p className='mt-4 text-lg text-darkGrayishBlue'>{message}</p>
      
      {/* Display feedback image if applicable */}
      {showImage && feedbackImage && (
        <img src={feedbackImage} alt='Feedback' className='mt-4' />
      )}

      {/* Button for the next question */}
      {message && (
        <button
          onClick={handleNextQuestion}
          className='bg-primaryRed text-white px-4 py-2 rounded hover:bg-red-700 transition duration-200 mt-4'
        >
          Next Question
        </button>
      )}
    </div>
  );
};

export default WordGuessingGame;
