import React from 'react';
import { FaProjectDiagram, FaCamera, FaVideo } from 'react-icons/fa'; // Import icons

const PortfolioSidebar = ({ activeSection, setActiveSection }) => {
  return (
    <div className='bg-gray-800 text-white flex flex-row md:flex-col h-20 md:h-auto w-full md:w-80 md:overflow-y-auto'>
      {/* "My Portfolio" is hidden on small screens and visible on larger screens */}
      <div className='hidden md:block p-6 text-center font-bold text-xl'>My Portfolio</div>
      
      <nav className='flex flex-row justify-around items-center w-full md:flex-col md:items-start md:space-y-4'>
        <button
          className={`py-2 px-6 flex items-center space-x-2 ${
            activeSection === 'ProfessionalProjects' ? 'bg-primaryRed rounded-lg text-white' : 'text-gray-400 hover:text-white'
          }`}
          onClick={() => setActiveSection('ProfessionalProjects')}
        >
          <FaProjectDiagram className='text-lg' />
          <span className='hidden md:inline-block'>Projects</span>
        </button>
        
        <button
          className={`py-2 px-6 flex items-center space-x-2 ${
            activeSection === 'Photography' ? 'bg-primaryRed rounded-lg text-white' : 'text-gray-400 hover:text-white'
          }`}
          onClick={() => setActiveSection('Photography')}
        >
          <FaCamera className='text-lg' />
          <span className='hidden md:inline-block'>Photos</span>
        </button>
        
        <button
          className={`py-2 px-6 flex items-center space-x-2 ${
            activeSection === 'Videography' ? 'bg-primaryRed rounded-lg text-white' : 'text-gray-400 hover:text-white'
          }`}
          onClick={() => setActiveSection('Videography')}
        >
          <FaVideo className='text-lg' />
          <span className='hidden md:inline-block'>Videos</span>
        </button>
      </nav>
    </div>
  );
};

export default PortfolioSidebar;
