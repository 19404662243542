import { TiEdit } from "react-icons/ti";

const videoData = {
  trekking: [
    { 
      id: 1, 
      platform: 'youtube',
      youtubeId: '6_Wj4EJTW1Q?si=_E7gWgt2A623pkIP', 
      title: 'Shey Phoksundo Trek', 
      description: 'A beautiful trek in the mountains.' 
    },
    { 
      id: 2,
      platform: 'youtube', 
      youtubeId: 'NL-DcwDRuVQ?si=MCKFIZvw_4Aj14cu', 
      title: 'Annapurna Base Camp Trek', 
      description: 'Another amazing trek journey.' 
    },
    { 
      id: 3, 
      platform: 'youtube',
      youtubeId: 'Ed_Dl9SmAq0?si=u_ehAQGlBObVcq2X', 
      title: 'Manang-Annapurna Circuit Trek', 
      description: 'Another amazing trek journey.' 
    },
    { 
      id: 4, 
      platform: 'youtube',
      youtubeId: 'Zpr835vEb_U?si=gFege74AHY36FYai', 
      title: 'Sikles-Kahphuche Trek', 
      description: 'Another amazing trek journey.' 
    },
  ],
  reels: [
    {
      id: 1,
      platform: 'instagram',
      instagramId: 'https://www.instagram.com/reel/C7-YIuuuRcw/',
      Title: 'Niagara Falls',
    },
    {
      id: 2,
      platform: 'instagram',
      instagramId: 'https://www.instagram.com/reel/Cknkp5EKjdj/',
      Title: 'Taal Pari - Pokhara',
    },
    {
      id: 3,
      platform: 'instagram',
      instagramId: 'https://www.instagram.com/reel/CpfM9idqUww/',
      Title: 'Taal Pari - Pokhara',
    },
    {
      id: 3,
      platform: 'instagram',
      instagramId: 'https://www.instagram.com/reel/CiSoq82B1kj/',
      Title: 'Taal Pari - Pokhara',
    },
  ],
  weddings: [
    // { 
    //   id: 1, 
    //   youtubeId: 'exampleWedding1', 
    //   title: 'Wedding Highlights 1', 
    //   description: 'Beautiful wedding moments captured.' 
    // },
    // { 
    //   id: 2, 
    //   youtubeId: 'exampleWedding2', 
    //   title: 'Wedding Highlights 2', 
    //   description: 'Memorable wedding day.' 
    // },
  ],
  freelance: [
    // { 
    //   id: 1, 
    //   youtubeId: 'exampleFreelance1', 
    //   title: 'Freelance Work 1', 
    //   description: 'Freelance project video.' 
    // },
    // { id: 2, 
    //   youtubeId: 'exampleFreelance2', 
    //   title: 'Freelance Work 2', 
    //   description: 'Another freelance video.' 
    // },
  ],
};

export default videoData;