import { type } from "@testing-library/user-event/dist/type";

const PortfolioImages = {
  Landscapes: [
    {
      id: 1,
      type: 'photo',
      title: 'Manang-Annapurna Circuit Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m1.jpg',
      alt: 'On the way to Shree Kharka'
    },
    {
      id: 2,
      type: 'photo',
      title: 'Manang-Annapurna Circuit Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m2.jpg',
      alt: 'The first golden rays of sunlight gently caressing the mountain peaks'

    },
    {
      id: 3,
      type: 'photo',
      title: 'Manang-Annapurna Circuit Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m3.jpg',
      alt: 'Captured: Me immersed in the beauty of the mountainous landscapes, framed perfectly by Pujan through a telephoto lens.'
    },
    {
      id: 4,
      type: 'photo',
      title: 'Manang-Annapurna Circuit Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m4.jpg',
      alt: 'A small pond humorously dubbed "high copy Ice Lake" on the way to Ice Lake.'
    },
    {
      id: 5,
      type: 'photo',
      title: 'Mardi Himal Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m5.jpg',
      alt: 'Awakening to the Majestic Beauty of Mt. Machhapuchhre. A Serene Start to a New Day!'
    },
    {
      id: 6,
      type: 'photo',
      title: 'Langtang Valley Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m6.jpg',
      alt: 'Chasing mountain dreams, Pandey strides confidently toward the breathtaking embrace of Langtang Valley'
    },
    {
      id: 7,
      type: 'photo',
      title: 'Annapurna Base Camp Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m7.jpg',
      alt: 'Mt Annapurna just before sunset. The golden hour casts a warm glow on the mountain peaks.'
    },
    {
      id: 8,
      type: 'photo',
      title: 'Annapurna Base Camp Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m8.jpg',
      alt: 'As twilight settles over Mt. Annapurna, the first stars emerge, urging us to capture the celestial dance above this majestic peak.',
    },
    {
      id: 9,
      type: 'photo',
      title: 'Annapurna Base Camp Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m9.jpg',
      alt: 'Trekkers enjoying the view - Annapurna Base Camp'

    },
    {
      id: 10,
      type: 'photo',
      title: 'Annapurna Base Camp Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m11.jpg',
      alt: 'Annapurna Base Camp - Before Sunrise. The first golden rays of sunlight gently caressing the mountain peaks.'
    },
    {
      id: 11,
      type: 'photo',
      title: 'Sikles-Kahphuche Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m13.jpg',
      alt: 'Kahphuche Lake: Located at the lowest altitude among Nepal\'s glacial lakes, Kahphuche Lake is a hidden gem that beautifully reflects the majestic Mt. Lamjung'
    },
    {
      id: 12,
      type: 'photo',
      title: 'Mardi Himal Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m15.jpg',
      alt: 'Mt. Machhapuchhre - Fish Tail Mountain. Gloomy weather adds a mystical touch to the mountain peaks.'
    },
    {
      id: 13,
      type: 'photo',
      title: 'Manaang-Annapurna Circuit',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m17.jpg',
      alt: 'Rafal on a snowy adventure, scanning the mountains for elusive snow leopards with his trusty telescope.'
    },
    {
      id: 14,
      type: 'photo',
      title: 'Mardi Himal Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m18.jpg',
      alt: 'Machhapuchhre - Before Sunrise. Dawn breaks over the mountain peaks, casting a warm glow on the snow-capped peaks.'
    },
    {
      id: 15,
      type: 'photo',
      title: 'Manaang-Annapurna Circuit',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m19.jpg',
      alt: 'Pony on the way to Thorang La Pass - Annapurna Circuit.',
    },
    {
      id: 16,
      type: 'photo',
      title: 'Poonhill Trek - First Adventure',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m20.jpg',
      alt: 'Our first adventure together - Poonhill Trek. Above the clouds at Poonhill, we watched the sunrise set the sky aglow."'
    },
    {
      id: 17,
      type: 'photo',
      title: 'Mardi Himal Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m21.jpg',
      alt: 'Mt. Machhapuchhre, where the fog kisses its peak and sunlight reveals its grandeur – a perfect blend of mystery and majesty.'
    },
    {
      id: 18,
      type: 'photo',
      title: "Annapurna Base Camp Trek",
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m22.jpg',
      alt: 'Magestic Annapurna Base Camp.'
    },
    {
      id: 19,
      type: 'Annapurna Base Camp Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m24.jpg',
      alt: 'Sikles Kahphuche Trek'
    },
    {
      id: 25,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m25.jpg',
      alt: 'Mardi Trek'
    },
    {
      id: 26,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m26.jpg',
      alt: 'Lamjung Himal'
    },
    {
      id: 27,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m27.jpg',
      alt: 'Annapurna Base Camp'
    },
    {
      id: 28,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m28.jpg',
      alt: 'On the way to Tilicho Lake - Annapurna Circuit'
    },
    {
      id: 29,
      type: 'photo',
      title: 'Manang-Annapurna Circuit Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m30.jpg',
      alt: 'The peaceful charm of a Braga village in Manang'
    },
    {
      id: 30,
      type: 'photo',
      title: 'Manang-Annapurna Circuit Trek',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m31.jpg',
      alt: 'The stunning colors of the sky as the sun sets over Braga village in Manang. A truly peaceful and serene moment in this beautiful highland valley.'
    }
  ],
  Heritage: [
    {
      id: 1,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/t1.jpg',
      alt: 'Temple'
    },
    {
      id: 2,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/t2.jpg',
      alt: 'Boudha Stupa'
    },
    {
      id: 3,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/t3.jpg',
      alt: 'Krishna Mandir'
    },
    {
      id: 4,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/t4.jpg',
      alt: 'Lumbini'
    },
    {
      id: 5,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/t5.jpg',
      alt: 'Muktinath'
    },
    {
      id: 6,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/t6.jpg',
      alt: 'Nyatpole'
    },
    {
      id: 7,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/t7.jpg',
      alt: 'Pashupati Nath Temple'
    },
    {
      id: 8,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/t8.jpg',
      alt: 'Pashupati Nath Temple - During Sunset'
    },
    {
      id: 9,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/t10.jpg',
      alt: 'Swayambhu Stupa'
    }
  ],
  Extras: [
    {
      id: 1,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e1.jpg',
      alt: 'Tea Shop'
    },
    {
      id: 2,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e2.jpg', 
      alt: 'Tea Shop'
    },
    {
      id: 3,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e3.jpg', 
      alt: 'Annapurna Base Camp - Trekker walking on a suspension bridge'
    },
    {
      id: 4,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e4.jpg', 
      alt: 'Pegion - Basantapur Durbar Square - Kali Temple'
    },
    {
      id: 5,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e5.jpg', 
      alt: 'Fewa Lake Morning View - Pokhara'
    },
    {
      id: 6,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e6.jpg', 
      alt: 'Jung Bahadura Rana Statue at Bhadrakali'
    },
    {
      id: 7,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e7.jpg', 
      alt: 'Sunrise - Kalinchowk'
    },
    {
      id: 8,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e8.jpg', 
      alt: 'Harihar Bhawan - Pulchowk Campus'
    },
    {
      id: 9,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e9.jpg',
      alt: 'Devils Tears - Nusa Lembongan'
    },
    {
      id: 10,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/m12.jpg',
      alt: 'Forest Trail - Gosaikunda Trek'
    },
    {
      id: 11,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e10.jpg',
      alt: 'Devils Tears - Nusa Lembongan'
    },
    {
      id: 12,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e11.jpg',
      alt: 'Devils Tears - Nusa Lembongan'
    },
    {
      id: 13,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e12.jpg',
      alt: 'Devils Tears - Nusa Lembongan'
    },
    {
      id: 14,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e13.jpg',
      alt: 'Devils Tears - Nusa Lembongan'
    },
    {
      id: 15,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/e14.jpg',
      alt: 'Devils Tears - Nusa Lembongan'
    },
  ],
  Wedding: [
    {
      id: 1,
      type: 'photo',
      title: 'Puja & Prabin',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/w1.jpg',
      alt: 'Tea Shop'
    },
    {
      id: 2,
      type: 'photo',
      title: 'Puja & Prabin',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/w2.jpg', 
      alt: 'Tea Shop'
    },
    {
      id: 3,
      type: 'photo',
      title: 'Puja & Prabin',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/w3.jpg', 
      alt: 'Annapurna Base Camp - Trekker walking on a suspension bridge'
    },
    {
      id: 4,
      type: 'photo',
      title: 'Puja & Prabin',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/w4.jpg', 
      alt: 'Pegion - Basantapur Durbar Square - Kali Temple'
    },
    {
      id: 5,
      type: 'photo',
      title: 'Puja & Prabin',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/w5.jpg', 
      alt: 'Fewa Lake Morning View - Pokhara'
    },
    {
      id: 6,
      type: 'photo',
      title: 'Puja & Prabin',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/w6.jpg', 
      alt: 'Jung Bahadura Rana Statue at Bhadrakali'
    },
    {
      id: 7,
      type: 'photo',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/w7.jpg', 
      alt: 'Sunrise - Kalinchowk'
    },
    {
      id: 8,
      type: 'photo',
      title: 'Puja & Prabin',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/w8.jpg', 
      alt: 'Harihar Bhawan - Pulchowk Campus'
    },
    {
      id: 9,
      type: 'photo',
      title: 'Puja & Prabin',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/w9.jpg',
      alt: 'Devils Tears - Nusa Lembongan'
    },
    {
      id: 10,
      type: 'photo',
      title: 'Puja & Prabin',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/w10.jpg',
      alt: 'Forest Trail - Gosaikunda Trek'
    },
    {
      id: 11,
      type: 'photo',
      title: 'Puja & Prabin',
      src: 'https://portfoliobucket162.s3.us-east-2.amazonaws.com/Portfolio+-+Photos/Portfolio+Images/w11.jpg',
      alt: 'Forest Trail - Gosaikunda Trek'
    },
  ],
};

export default PortfolioImages;  