import React from 'react';
import videoData from '../assets/data/videoData';
import VideoSection from './VideoSection';

const Videography = () => {
  return (
    <div className="container mx-auto p-4">
      {/* Trekking Videos */}
      <VideoSection title="Trekking" videos={videoData.trekking} />

      {/* Reels */}
      <VideoSection title="Reels" videos={videoData.reels} />

      {/* Wedding Videos */}
      {/* <VideoSection title="Weddings" videos={videoData.weddings} /> */}

      {/* Freelance Videos */}
      {/* <VideoSection title="Freelance Work" videos={videoData.freelance} /> */}
    </div>
  );
};

export default Videography;
