import React from 'react';
import { FaGithub, FaLinkedin, FaTwitter, FaEnvelope } from 'react-icons/fa';
import AvatarImage from '../assets/img/message.svg'; 
import { Helmet } from 'react-helmet';
import { useForm, ValidationError } from '@formspree/react';

const Contact = () => {
  const [state, handleSubmit] = useForm("xanyqvrl");
  if (state.succeeded) {
      return <p>Message Delivered</p>;
  }
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-200 flex flex-col items-center justify-center px-4 pt-4 md:px-8">
      <Helmet>
        <title>Contact Page - Saroj Niraula | Developer Portfolio - Saroj Niraula | ON, Canada</title>
        <meta name="description" content="Explore the portfolio of Saroj Niraula, a Full Stack Developer based in ON, Canada." />
      </Helmet>

      <div className="flex flex-col items-center space-y-6 mb-12">
        <h1 className="text-5xl font-extrabold text-primaryRed text-center">Let's Connect!</h1>
        <p className="text-lg text-gray-700 text-center max-w-2xl">
          I'm excited to hear from you! Whether it's a project collaboration, questions, or just a quick hello, feel free to drop me a message.
        </p>

=        <div className="w-40 h-40 md:w-60 md:h-60">
          <img
            src={AvatarImage}
            alt="Avatar"
            className="w-full object-cover"
          />
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-lg">
        <form onSubmit={handleSubmit} className="space-y-6">
          
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Your Name</label>
            <input
              type="text"
              id="name"
              name="name"
              required
              className="mt-1 block w-full px-4 py-2 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:ring-primaryRed focus:border-primaryRed"
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="mt-1 block w-full px-4 py-2 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:ring-primaryRed focus:border-primaryRed"
            />
            <ValidationError 
              prefix="Email" 
              field="email"
              errors={state.errors}
            />
          </div>

          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">Your Message</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              required
              className="mt-1 block w-full px-4 py-2 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:ring-primaryRed focus:border-primaryRed"
            />
            <ValidationError 
              prefix="Message" 
              field="message"
              errors={state.errors}
            />
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-primaryRed text-white px-6 py-3 rounded-full hover:bg-red-600 transition duration-300"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>

      <div className="flex justify-center space-x-6 mt-12">
        <a href="https://github.com/sarniraula" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-primaryRed transition duration-300">
          <FaGithub className="text-3xl" />
        </a>
        <a href="https://linkedin.com/in/saroj-niraula-288991165" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-primaryRed transition duration-300">
          <FaLinkedin className="text-3xl" />
        </a>
        <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-primaryRed transition duration-300">
          <FaTwitter className="text-3xl" />
        </a>
        <a href="mailto:your-email@gmail.com" className="text-gray-600 hover:text-primaryRed transition duration-300">
          <FaEnvelope className="text-3xl" />
        </a>
      </div>
    </div>
  );
};

export default Contact;