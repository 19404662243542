import React from 'react';
import { FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { SiGmail } from 'react-icons/si';
import Namastee from '../assets/img/logobb.png';

const Footer = () => {
  return (
    <footer className='bg-gradient-to-r from-slate-200 to-slate-300 py-8'>
      <div className='container mx-auto px-6 md:px-12'>
        <div className='flex flex-col md:flex-row justify-between items-center md:items-start space-y-6 md:space-y-0'>
          
          {/* Logo Section */}
          <div className='flex justify-center md:justify-start mb-6 md:mb-0'>
            <div className='bg-gradient-to-r rounded-2xl from-slate-200 to-slate-50 shadow-primary hover:scale-105 transition duration-300 ease-in-out hover:shadow-lg'>
              <img className='h-32 md:h-48' src={Namastee} alt='Logo' />
            </div>
          </div>

          {/* Bio Section */}
          <div className='text-center md:text-left'>
            <h2 className='text-2xl font-bold text-primaryRed mb-3'>Stay Connected</h2>
            <p className='max-w-sm text-gray-700'>
              Thanks for visiting! I'm a full stack developer, passionate photographer, and adventure enthusiast. Let's connect and create something awesome together!
            </p>

             {/* Social Media Links */}
          <div className='flex justify-center md:justify-start space-x-6 mt-6'>
            <a href='https://github.com/sarniraula' target='_blank' rel='noopener noreferrer' className='text-2xl text-primaryRed hover:text-gray-700 transition-colors duration-300'>
              <FaGithub className='transition-transform transform hover:scale-110 duration-300' />
            </a>
            <a href='https://www.linkedin.com/in/saroj-niraula-288991165/' target='_blank' rel='noopener noreferrer' className='text-2xl text-primaryRed hover:text-gray-700 transition-colors duration-300'>
              <FaLinkedin className='transition-transform transform hover:scale-110 duration-300' />
            </a>
            <a href='https://x.com/SarojNiraula17' target='_blank' rel='noopener noreferrer' className='text-2xl text-primaryRed hover:text-gray-700 transition-colors duration-300'>
              <FaXTwitter className='transition-transform transform hover:scale-110 duration-300' />
            </a>
            <a href='https://www.instagram.com/sar.niraula/' target='_blank' rel='noopener noreferrer' className='text-2xl text-primaryRed hover:text-gray-700 transition-colors duration-300'>
              <FaInstagram className='transition-transform transform hover:scale-110 duration-300' />
            </a>
            <a href='mailto:sar.niraula@gmail.com' className='text-2xl text-primaryRed hover:text-gray-700 transition-colors duration-300'>
              <SiGmail className='transition-transform transform hover:scale-110 duration-300' />
            </a>
          </div>
          </div>

          {/* Quick Links Section */}
          <div className='text-center md:text-left'>
            <h3 className='text-xl font-semibold text-primaryRed mb-3'>Quick Links</h3>
            <ul className='space-y-2'>
              <li>
                <a href='/' className='text-gray-700 text-sm hover:text-primaryRed transition-colors duration-300'>Home</a>
              </li>
              <li>
                <a href='/about' className='text-gray-700 text-sm hover:text-primaryRed transition-colors duration-300'>About Me</a>
              </li>
              <li>
                <a href='/portfolio' className='text-gray-700 text-sm hover:text-primaryRed transition-colors duration-300'>Portfolio</a>
              </li>
              <li>
                <a href='/resume' className='text-gray-700 text-sm hover:text-primaryRed transition-colors duration-300'>Resume</a>
              </li>
              <li>
                <a href='/contact' className='text-gray-700 text-sm hover:text-primaryRed transition-colors duration-300'>Contact</a>
              </li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className='mt-8 border-t border-slate-400 pt-6 text-center'>
          <p className='text-sm text-gray-700'>
            &copy; {new Date().getFullYear()} Saroj Niraula. All Rights Reserved. | Built with React.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
