import React, { useState } from 'react';
import PortfolioImages from '../assets/data/photoData';
import { FaTimes } from 'react-icons/fa';
import PhotoSection from './PhotoSection';

const Photography = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const closeModal = () => {
    setSelectedImage(null);
  };
  return (
    <div className="container mx-auto p-2">
      <PhotoSection
        title="Landscapes"
        photos={PortfolioImages.Landscapes}
      />

      <PhotoSection
        title="Heritage"
        photos={PortfolioImages.Heritage}
      />

      <PhotoSection
        title="Extras"
        photos={PortfolioImages.Extras}
      />

      <PhotoSection
        title="Weddings"
        photos={PortfolioImages.Wedding}
      />

      {/* Modal for Enlarged Image */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative">
            {/* Close Button */}
            <button
              className="absolute top-2 right-2 text-primaryRed text-2xl"
              onClick={closeModal}
            >
              <FaTimes />
            </button>

            {/* Enlarged Image */}
            <img
              src={selectedImage}
              alt="Enlarged landscape"
              className="max-w-full max-h-screen rounded-lg shadow-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Photography;
