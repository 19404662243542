import { BiCameraMovie, BiCycling } from 'react-icons/bi';
import { FaReact, FaNodeJs, FaGithub, FaAws, FaBasketballBall, FaSwimmer, FaSkating, FaCamera, FaHiking } from 'react-icons/fa';
import { FaFootball } from 'react-icons/fa6';
import { GiDeliveryDrone } from 'react-icons/gi';
import { IoMdFitness } from 'react-icons/io';
import { SiNextdotjs, SiExpress, SiMongodb, SiPostgresql, SiDocker, SiKubernetes } from 'react-icons/si';

const hobbyIcons = [
    { id: 1, icon: <FaBasketballBall className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'React' },
    { id: 2, icon: <FaSwimmer className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'Next.js' },
    { id: 3, icon: <BiCycling className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'Node.js' },
    { id: 4, icon: <FaSkating className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'Express.js' },
    { id: 5, icon: <BiCameraMovie className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'GitHub' },
    { id: 6, icon: <FaCamera className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'MongoDB' },
    { id: 7, icon: <FaHiking className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'PostgreSQL' },
    { id: 8, icon: <IoMdFitness className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'Docker' },
    { id: 9, icon: <GiDeliveryDrone className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'AWS' },
    { id: 10, icon: <FaFootball className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'Kubernetes' }
]

const skillIcons = [
    { id: 1, icon: <FaReact className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'React' },
    { id: 2, icon: <SiNextdotjs className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'Next.js' },
    { id: 3, icon: <FaNodeJs className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'Node.js' },
    { id: 4, icon: <SiExpress className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'Express.js' },
    { id: 5, icon: <FaGithub className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'GitHub' },
    { id: 6, icon: <SiMongodb className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'MongoDB' },
    { id: 7, icon: <SiPostgresql className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'PostgreSQL' },
    { id: 8, icon: <SiDocker className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'Docker' },
    { id: 9, icon: <FaAws className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'AWS' },
    { id: 10, icon: <SiKubernetes className='text-3xl text-primaryRed hover:animate-pulse' />, name: 'Kubernetes' },
]

export default { hobbyIcons, skillIcons };